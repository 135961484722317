import {Duck} from "./components/Duck";
import {Canvas} from "@react-three/fiber";
import {Box} from "./components/Box";
import {Scene} from "./components/Scene";
import {Logo} from "./components/Logo";

// URL Handling: https://medium.com/revo-digital-blog/api-url-management-in-react-projects-aad382de3139
// Filename for buld file: https://webpack.js.org/guides/caching/

export function App({part}) {
  const getPart = () => {
    switch (part) {
      case 'logo':
        return <Logo />;

      case 'star':
        return <Scene />

      case 'duck':
        return <Duck />

      /*case 'box':
        return <Box />*/
    }
  }

  return (
    <>
      {getPart()}
    </>
  )
}