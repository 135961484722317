import { motion } from "framer-motion-3d"
import {useGLTF} from "@react-three/drei";

// Tutorial: https://medium.com/nicasource/3d-animations-with-framer-motion-13445d46e8c5

export function Star({hovered, clicked}){
  const lights = [
    [2, 1, 37, 1],
    [8, 5, 37, 1]
  ];
  const {nodes} = useGLTF(process.env.REACT_APP_BASE_PATH + 'models/star.glb');

  return (
    <motion.group dispose={null}>
      {/*<directionalLight position={[10, 10, 5]} intensity={2}/>*/}
      {lights.map(([x, y, z, intensity], i) => (
        <pointLight
          key={i}
          intensity={intensity}
          position={[x / 8, y / 8, z / 8]}
          color="#fff"
        />
      ))}
      <motion.mesh
        position={[0,0,4.2]}
        geometry={nodes.Star.geometry}
        rotation={[Math.PI / 2, 0, 360 * Math.PI / 180]}
        animate={hovered ? "hover" : "stale"}
        variants={{
          stale: {
            rotateZ: 360 * Math.PI / 180,
          },
          hover: {
            scale: 1.2,
            rotateZ: 0,
            transition: {
              rotateZ: {duration: 2, ease: "linear", repeat: Infinity}
            }
          }
        }}
      >
        <meshPhongMaterial
          color={clicked ? "#ffdd00" : "#00ddff"}
          emissive={clicked ? "#ffdd00" : "#00ddff"}
          specular="#fff"
          shininess="50"
        />
      </motion.mesh>
    </motion.group>
  )
}