import { Canvas } from '@react-three/fiber'
import { Stats, OrbitControls, Environment, useGLTF } from '@react-three/drei'

const Models = [
  { title: 'Duck', url: process.env.REACT_APP_BASE_PATH + 'models/duck.glb' },
]

function Model({ url }) {
  const { scene } = useGLTF(url)
  return <primitive object={scene} />
}

export function Duck() {

  return (
    <Canvas>
        <directionalLight position={[10, 10, 5]} intensity={2}/>
        <directionalLight position={[-10, -10, -5]} intensity={1}/>

        <Model
          url={Models[Models.findIndex((m) => m.title === 'Duck')].url}
        />
        <OrbitControls autoRotate/>
        <Stats/>
    </Canvas>
  )
}