import {Sphere} from "./Sphere";
import {useState} from "react";
import {Canvas} from "@react-three/fiber";
import {motion, spring, useMotionValue, useSpring, useTransform} from "framer-motion"
import {motion as motion3d} from "framer-motion-3d";
import {OrbitControls, useGLTF} from "@react-three/drei";
import * as THREE from 'three';

export function Logo(){
  const {nodes} = useGLTF(process.env.REACT_APP_BASE_PATH + 'models/wesign_logo.gltf');

  return(
    <motion.div>
      <Canvas>
        <motion3d.group>
          <directionalLight color="#D7F205" intensity={5} position={[-0.5, 0, 1]}/>
          <directionalLight color="#D7F205" intensity={1} position={[0.3, 0.2, 1]}/>

          <motion3d.mesh
            position={[0, .5, 0]}
            geometry={nodes.mesh_0.geometry}
            rotation={[15 * Math.PI / 180, 0, 0]}
            material={new THREE.MeshPhongMaterial({
              color: '#0A0A0A'
            })}
            scale={10}
            animate={{
              rotateY: -360 * Math.PI / 180,
              transition: {
                rotateY: {
                  duration: 10,
                  ease: "linear",
                  repeat: Infinity,
                },
              }
            }}
          >
          </motion3d.mesh>
        </motion3d.group>

      </Canvas>
    </motion.div>
  )
    ;
}

export function Lights() {
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  return (
    <motion3d.group
      onPointerMove={(e) => {
        mouseX.set(e.clientX);
        mouseY.set(e.clientY);

        console.log(mouseX);
      }}
      rotation={[mouseX, mouseY]}
    >
      <spotLight color="#61dafb" position={[-10, -10, -10]} intensity={0.2}/>
      <spotLight color="#61dafb" position={[-10, 0, 15]} intensity={0.8}/>
      <spotLight color="#61dafb" position={[-5, 20, 2]} intensity={0.5}/>
      <spotLight color="#f2056f" position={[15, 10, -2]} intensity={2}/>
      <spotLight color="#f2056f" position={[15, 10, 5]} intensity={1}/>
      <spotLight color="#b107db" position={[5, -10, 5]} intensity={0.8}/>
    </motion3d.group>
  );
}

/*



Helper
<axesHelper args={[5]}/>
<OrbitControls/>
*/