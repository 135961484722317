import {Star} from "./Star";
import {useState} from "react";
import {Canvas} from "@react-three/fiber";
import {motion} from "framer-motion";
import {Box} from "./Box";
import {Duck} from "./Duck";


export function Scene(){
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <motion.div
      style={{width: "200px", height: "200px", margin: "calc(50% - 100px) auto"}}
      onHoverStart={() => {
        setIsHovered(true)
      }}
      onHoverEnd={() => setIsHovered(false)}
      onClick={() => setIsClicked(!isClicked)}
    >
      <Canvas>
        <Star
          hovered={isHovered}
          clicked={isClicked}
        />

        {/*
        <Box
          hovered={isHovered}
          clicked={isClicked}
        />
        */}
        {/*
          <Duck />
        */}
      </Canvas>
    </motion.div>
);
}